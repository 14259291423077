import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { addClassToId, hideElementById, removeClassFromClassName, showElementById } from "../../util/hideShowElementById";

import paypal from "../../images/paypal.webp";
import cardlogo from "../../images/cardlogo.webp";
import crypto from "../../images/crypto.webp";
import ideal from "../../images/ideal.png";

import sadShoppingBag from "../../images/sadshoppingbag.webp";

import "./shoppingCart.css"
import ShoppingCartItem from "./shoppingCartItem";

import { getShoppingCart } from "../../services/shoppingCart";
import { getCookie, setCookie } from "../../util/cookies";
import { createInvoice } from "../../services/invoice";
import { addAlert } from "../../util/showNotif";
import { useCurrency } from "../../context/currencyContext";
import { getLocalStorageItem } from "../../util/localstorage";

function ShoppingCart()
{
    const { currency } = useCurrency();

    const [ shoppingCart, setShoppingCart ] = useState({shoppingCartItems: []});
    const [ changed, setChanged ] = useState(true);

    const [ email, setEmail ] = useState("");
    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");

    const [ isDisabled, setIsDisabled ] = useState(false);

    const [ itemsPrices, setItemsPrice ] = useState(0.00);
    const [ processingFees, setProcessingFees ] = useState(0.00);
    const [ discount, setDiscount ] = useState(0.00);

    const [ paymentMethod, setPaymentMethod ] = useState("");

    const onload = async () =>
    {
        const response = await getShoppingCart();
        setShoppingCart(response.data);
        
        let calculatedItemsPrice = 0;

        response.data.shoppingCartItems = response.data.shoppingCartItems.sort((a, b) => b.product.title.localeCompare(a.product.title));

        response.data.shoppingCartItems.forEach(item => {
            calculatedItemsPrice += (item.product.price * item.quantity);
        });

        setItemsPrice(calculatedItemsPrice);
    }

    const checkout = async () => 
    {
        try
        {
            if(paymentMethod === "PayPal")
                return addAlert("Join the discord server and create a ticket to buy with PayPal. Otherwise, choose another payment method.", "warning", 7000);

            if(!email || email === "")
            {
                addClassToId('s-pm-email', 's-c-customer-input-error')

                return addAlert("Fill out email and discord username.", "warning", 3000)
            }else if(paymentMethod === ""){
                return addAlert("Pick a payment method.", "warning", 3000)
            // }else if(paymentMethod === "Card"){
            //     addAlert("Card payments are down. Join the discord to pay with CashApp/Venmo/PayPal/LTC", "warning", 3000);
            }else{
                hideElementById("proceed-to-checkout");
                showElementById("loading-pay", "flex");
    
                const referrer = getCookie("referrer");
                const customerStatus = getCookie("CustomerStatus");

                const SCID = getLocalStorageItem("SCID");

                const order = 
                {
                    paymentMethod: paymentMethod,
                    referrer: referrer,
                    shoppingCartId: SCID,
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    customerStatus: customerStatus
                }

                const response = await createInvoice(order);

                const responseData = response.data;

                switch(responseData)
                {
                    case '':
                    case null:
                    case undefined:
                        addAlert("Please refresh the page and try again. Make sure you are not on a VPN. If it still doesn't work join the ticket and create a ticket.", "warning", 4000);
                        break;
                    case 'PROXY':
                        addAlert("VPN/Proxy detected! Turn it off to continue to checkout.", "warning", 4000)
                        break;
                    case 'EMPTY_CART':
                        addAlert("Shopping cart is empty!", "warning", 4000);
                        break;
                    case 'CC_DISABLED':
                        addAlert("Card payments down. Join the discord server to pay with PayPal, Zelle, Venmo or LTC.", "error", 6000);
                        break;
                    case 'INVALID_ACCESS':
                        addAlert("You can't purchase under the minimum quantity.", "error", 6000)
                        break;
                    default:
                        if(responseData.includes("4WK4L3ET4E"))
                            setCookie("CustomerStatus", "common", 365)

                        window.location.href = responseData;
                        break;
                }
            }
        }catch(err){
            console.log(err)
        }

        hideElementById("loading-pay");
        showElementById("proceed-to-checkout");
    }

    const setPm = (pm) => 
    {
        removeClassFromClassName("s-c-payment-method-option", "s-c-pm-selected");
        addClassToId(pm, "s-c-pm-selected");

        if(pm === "Crypto")
            setDiscount(itemsPrices * .1);
        else
            setDiscount(0.00);
        
        setPaymentMethod(pm);
    }

    /**
     * On user input it will change the state of email which is the delivery email.
     * 
     * @param {*} e The onChange event.
     */
    const handleEmailChange = (e) => { setEmail(e.target.value) };

    /**
     * On user input it will change the state of first name which is stored for referring purposes.
     * 
     * @param {*} e The onChange event.
     */
    const handleFirstNameChange = (e) => { setFirstName(e.target.value) };

    /**
     * On user input it will change the state of last name which is stored for referring purposes.
     * 
     * @param {*} e The onChange event.
     */
    const handleLastNameChange = (e) => { setLastName(e.target.value) };

    useEffect(() => {
        hideElementById('pn-nav-bar');

        const UTOK = getCookie("UTOK");
        if(UTOK)
        {
            const tokenParts = UTOK.split(".");

            const payload = JSON.parse(atob(tokenParts[1]));

            setEmail(payload.username);
            setIsDisabled(!isDisabled);
        }
    }, [])

    useEffect(() => {
        onload();
    }, [changed])

    return (
        <div className="shopping-cart-cont">
            {/*<CashApp orderId={orderId} price={(itemsPrices - discount.toFixed(2))} />*/}
            <div className="shopping-cart-header">Shopping Cart</div>
            <div className="shopping-cart-main-cont">
                <div className="shopping-cart-items-cont">
                    <div className="shopping-cart-items-products">
                        {
                            shoppingCart.shoppingCartItems.length !== 0 ? (shoppingCart.shoppingCartItems.map(shoppingCartItem => 
                            {
                                return <ShoppingCartItem shoppingCartItem={shoppingCartItem} changed={changed} setChanged={setChanged}/>
                            })) : 
                            <div className="shopping-cart-empty-cont">
                                <div className="s-c-is-empty-msg">Your Shopping Cart is Empty</div>
                                <img className="s-c-is-empty-img" src={sadShoppingBag}/>
                                <div className="s-c-is-browse-products">Browse products <Link className="s-c-is-browse-products-link" to={"/"}>here</Link></div>
                            </div>}
                    </div>
                    <div className="shopping-cart-items-recommendations">
                        <div className="shopping-cart-items-r-header">Check out our other top rated products</div>
                        <div className="shopping-cart-items-recommendations">
                    </div>
                    </div>
                </div>
                <div className="shopping-cart-p-m-cont">
                    <div className="s-c-i-item s-c-order-summary">
                        <div className="s-c-p-m-header">Order Summary</div>
                        <div className="s-c-p-m-pricing">
                            <div className="s-c-p-m-pricing-option">
                                <div>Items:</div>
                                <div>{currency.symbol}{(itemsPrices * (currency.rate)).toFixed(2)}</div>
                            </div>
                            <div id="s-c-processing-fee" className="s-c-p-m-pricing-option">
                                <div>Processing Fees:</div>
                                <div>{currency.symbol}0</div>
                            </div>
                            <div id="s-c-processing-fee" className="s-c-p-m-pricing-option">
                                <div>Discount:</div>
                                <div>{currency.symbol}{discount.toFixed(2)}</div>
                            </div>
                            {/* <div id="s-c-discounts" className="s-c-p-m-pricing-option s-c-p-m-pricing-discount">
                                <div>Discount 10%:</div>
                                <div>- {symbol}2.34</div>
                            </div> */}
                            <div className="s-c-p-m-pricing-option">
                                <div><strong>Total:</strong></div>
                                <div>{currency.symbol}{((itemsPrices - discount) * currency.rate).toFixed(2)}</div>
                            </div>
                        </div>
                        {/* <div className="s-c-p-m-promo">
                            <span className="s-c-p-m-promo-title">Coupon:</span>
                            <div className="s-c-p-m-promo-submit-cont">
                                <input className="s-c-p-m-promo-input" placeholder="Enter Coupon Code"/>
                                <button className="s-c-p-m-promo-submit">
                                    <img className="s-c-p-m-tag-img" src={tag} />
                                </button>
                            </div>
                        </div> */}
                    </div>
                    <div className="s-c-i-item">
                        <div id="s-c-customer-inputs-cont" className="s-c-customer-inputs">
                            <div className="s-c-customer-inputs-header">Delivery Email</div>
                            <input className="s-c-customer-input" placeholder="Enter Email" id="s-pm-email" onChange={handleEmailChange} value={email} disabled={isDisabled}/>
                            <div className="s-c-customer-inputs-header">First name</div>
                            <input className="s-c-customer-input" placeholder="Enter First Name" id="s-pm-first-name" onChange={handleFirstNameChange} value={firstName}/>
                            <div className="s-c-customer-inputs-header">Last Name</div>
                            <input className="s-c-customer-input" placeholder="Enter Last Name" id="s-pm-last-name" onChange={handleLastNameChange} value={lastName}/>
                        </div>
                    </div>
                    <div className="s-c-i-item">
                        <div className="s-c-p-m-header">Choose a payment method</div>
                        <div className="s-c-payment-methods">
                            {/* <div id="CashApp" className="s-c-payment-method-option" onClick={() => setPm("CashApp")}>
                                <div className="s-c-pm-main-cont">
                                    <img className="s-c-payment-method-option-img" src={cashapp}/> 
                                    <div className="s-c-payment-method-option-text">CashApp</div>
                                </div>
                                <div className="s-c-pm-main-discounts">
                                    10% Discount
                                </div>
                            </div> */}
                            {/* <div id="PayPal" className="s-c-payment-method-option" onClick={() => setPm("PayPal")}>
                                <div className="s-c-pm-main-cont">
                                    <img className="s-c-payment-method-option-img" src={paypal}/> 
                                    <div className="s-c-payment-method-option-text">PayPal</div>
                                </div>
                            </div> */}
                            <div id="Card" className="s-c-payment-method-option" onClick={() => setPm("Card")}>
                                <div className="s-c-pm-main-cont">
                                    <img className="s-c-payment-method-option-img" src={cardlogo}/>
                                    <div className="s-c-payment-method-option-text">Credit, Debit Card, Apple/Google Pay</div>
                                </div>
                            </div>
                            <div id="Ideal" className="s-c-payment-method-option" onClick={() => setPm("Ideal")}>
                                <div className="s-c-pm-main-cont">
                                    <img className="s-c-payment-method-option-img" src={ideal}/>
                                    <div className="s-c-payment-method-option-text">Ideal</div>
                                </div>
                            </div>
                            <div id="Crypto" className="s-c-payment-method-option" onClick={() => setPm("Crypto")}>
                                <div className="s-c-pm-main-cont">
                                    <img className="s-c-payment-method-option-img" src={crypto}/>
                                    <div className="s-c-payment-method-option-text">BTC/ETH</div>
                                </div>
                                <div className="s-c-pm-main-discounts">
                                    10% Discount
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="s-c-i-item">
                        <div id="proceed-to-checkout" className="s-c-customer-checkout" onClick={checkout}>
                            Proceed to Checkout
                        </div>
                        <div id="loading-pay" className="s-c-loading-pay">
                            <div>Preparing Your Order</div>
                            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShoppingCart;