export default function AfterIPayHowLongDoINeedToWaitToReceiveMyProduct()
{
    return (
        <>
            <div className="support-questions-header">After I Pay How Long Do I Need To Wait To Receive My Product?</div>
            <article className="support-questions-solution">
                <p>Generally most orders will be sent as soon as the funds are received. However, check out the payment methods below to check exactly when you will receive the product after paying.</p>
                <section>
                    <h3 className="support-answers-section-header">Payment methods</h3>
                    <ul className="support-answers-ul">
                        <li><b>Crypto: </b>The product will be delivered as soon as the Crypto transaction gets confirmed on the blockchain.</li>
                        <li><b>Card/ApplePay/Google Pay/Ideal: </b> Immediately after paying you will be redirected to your order link where you can view your product.</li>
                    </ul>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}